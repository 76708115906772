import React, {createContext, useState, useMemo} from 'react';


const SocialMediaProvider = (props) =>{
    const [listExist, setListExist] = useState(false);
    const [socialMediaList, setSocialMediaList] = useState([]);
    const [show, setShow] = useState(false);
    const [social, setSocial] = useState('');
    const [url, setUrl] = useState('');
    const [idSocial, setIdSocial] = useState('');
    const [path, setPath] = useState('');



    const value = useMemo(()=>(
        {listExist, setListExist, socialMediaList, setSocialMediaList, show, setShow,
        social, setSocial, url, setUrl, idSocial, setIdSocial, path, setPath}),
    [listExist, setListExist, socialMediaList, setSocialMediaList, show ,setShow,
        social, setSocial, url, setUrl, idSocial, setIdSocial, path, setPath])
    return(
        <div>
            <SocialMediaContext.Provider value={value}>
                {props.children}
            </SocialMediaContext.Provider>
            
        </div>
    )
};


export default SocialMediaProvider;
export const SocialMediaContext = createContext(null);

