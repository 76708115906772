import React,{useEffect, useState, useContext} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import {Col, Container, Row, Modal, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { store } from "../../components/Firebase/firebase-config"
import FrmSocialMedia from '../Forms/FrmSocialMedia'
import toastMsg from '../Toast'
import {SocialMediaContext} from '../Application/providerSocialMedia'
// swall
import Swal from "sweetalert2";


const SocialMedia = () => {




    //USE CONTEXT
    const {listExist, setListExist, socialMediaList, setSocialMediaList, show, setShow, social, setSocial, url, setUrl, setIdSocial, path, setPath} = useContext(SocialMediaContext);

    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        readSocialMedia();
    }, [])


    const readSocialMedia = async ()=>{
        const businessCard = store.collection('businessCard');
        businessCard.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async (querySnapshot)=>{
            if(querySnapshot.size !=0){
                querySnapshot.forEach(async(doc) =>{
                    setPath(doc.data().path);
                })
            }else{
                console.log("Not found data")
            }
        });
        const socialMedia =  store.collection('socialMedia');
        const query =  socialMedia.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async (querySnapshot) => {
            if(querySnapshot.size ==0){
                toastMsg.infoToast("Ingrese su primera red social");
                setListExist(false);
            }else{
                var array = [];
                querySnapshot.forEach(async(doc) => {
                    var json = {
                        type: doc.data().type,
                        url: doc.data().url,
                        id: doc.id,
                        user: doc.data().user,
                    };
                    array.push(json); 
                });
                console.log(array)
                setSocialMediaList(array);
                setListExist(true);
            }
        })
        .catch((err)=>{
            console.log(err)
        });
    }
 

    
    
    function Conditional (){
        if(listExist){
            return(
                <>
                    <Container>
                        <Row>
                            <Col sm ={12} md={12} lg={12} className="mt-1 mb-4">
                                <button 
                                    className="btn btn-success float-md-end float-end"
                                    style={{
                                        "borderRadius":"25px"
                                    }}
                                    onClick={()=>{
                                        handleShow();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="size-icon"/>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                    
                    <Accordion>
                        {
                            socialMediaList.map((item, i)=>{
                                var urlpRUEBA;
                                return(
                                   <>
                                    <Accordion.Item eventKey={i}>
                                            <Accordion.Header>{item.type}</Accordion.Header>
                                            <Accordion.Body>
                                                    <Container>
                                                        <Row>
                                                            <Col sm={11} md={11} lg={11}>
                                                                <input 
                                                                    disabled
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id={item.id}
                                                                    value={item.url}
                                                                />
                                                            </Col>
                                                            <Col sm={1} md={1} lg={1}>
                                                                <button
                                                                    className="btn btn-success btn-sm mt-1 btn-block"
                                                                    onClick={()=>{
                                                                        setShow(true);
                                                                        setUrl(item.url);
                                                                        setSocial(item.type);
                                                                        setIdSocial(item.id);
                                                                    }}
                                                                >
                                                                    Editar
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                   </>
                                )
                                }
                            )
                        }
                    </Accordion>

                </>
            )
        }else{
            return(
                <>
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12}
                                onClick={()=>{handleShow()}}
                                style={{"cursor":"pointer"}}
                            >
                                <div className="text-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/argos-tool.appspot.com/o/global%2FaddNew.svg?alt=media&token=9e9b89b0-32a3-4060-ba55-6b89d112456e" alt=""
                                    style={{
                                        "height":"200px"
                                    }}
                                />
                                <h3 className="text-secondary">Agrega una nueva red social</h3>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                    
                </>
            )
        }
    };

    return (
        <>
            
            <Conditional />
            {/* MODALS */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Mantenimiento de red social</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FrmSocialMedia />
                </Modal.Body>
                
            </Modal>
        </>
    )
}

export default SocialMedia
