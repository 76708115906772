// name: FrProduct
// description: Form product whit add and edit
// author: OsmaroBonilla
// date 11/10/2021
import React,{useContext, useEffect} from 'react';
import toastMsg from "../Toast";
// import store db
import {store} from "../Firebase/firebase-config"
// import provider context
import { AppContext } from "../Application/provider";
import { SocialMediaContext } from "../Application/providerSocialMedia";
import { Card, Container, Col, Row } from 'react-bootstrap';
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faTelegram, faWhatsapp, faFacebook, faTwitter, faLinkedin, faPinterest, faTiktok} from '@fortawesome/free-brands-svg-icons'


const Movil = (props) => {
    const [
        name, 
        setName, 
        post, 
        setPost,
        description,
        setDescription,
        path, setPath,
        picture,
        setPicture
    ] = useContext(AppContext)

    const {setListExist, setSocialMediaList, socialMediaList}= useContext(SocialMediaContext)

    useEffect(() => {
        readSocialMedia();
    }, [])

    const readSocialMedia = async ()=>{
        const socialMedia =  store.collection('socialMedia');
        const query =  socialMedia.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async (querySnapshot) => {
            if(querySnapshot.size ==0){
                toastMsg.infoToast("Ingrese su primera red social");
                setListExist(false);
            }else{
                var array = [];
                querySnapshot.forEach(async(doc) => {
                    var json = {
                        type: doc.data().type,
                        url: doc.data().url,
                        id: doc.id,
                        user: doc.data().user
                    };
                    array.push(json); 
                });
                console.log(array)
                setSocialMediaList(array);
                setListExist(true);
            }
        })
        .catch((err)=>{
            console.log(err)
        });
    }





    return(
        <>
        <Container>
            <Row>
                <Col md={12}>
                    <Card className="shadow"
                        style={
                        {
                            "height":"600px",
                            "borderStyle": "solid",
                            "borderRadius": "25px",
                            "border": "4px solid #5C5C5C"
                        }
                    }>
                        <Card.Body>
                            <div className="text-center">
                                <img 
                                    src={picture} 
                                    className="justify-content-center img-center"
                                    alt=""
                                    style={{
                                        "borderRadius": "100px",
                                        "width": "150px",
                                        "height": "150px",
                                        "border": "1px solid #5C5C5C"
                                    }} 
                                />
                            </div>
                            <h3 className="text-center">{name}</h3>
                            <h4 className="text-center">{post}</h4>
                            <p className="text-center">{description}</p>
                            <div className="text-center">
                                <button className="btn btn-primary btn-sm m-1">Guardar</button>
                                <button className="btn btn-secondary btn-sm m-1">Compartir</button>
                            </div>
                            <div className="text-center ">
                                {
                                    socialMediaList.map((item, i)=>{
                                            if(item.type === "faInstagram"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faInstagram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faTelegram"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTelegram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faWhatsapp"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faWhatsapp} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faFacebook"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faFacebook} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faTwitter"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTwitter} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faLinkedin"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faLinkedin} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faPinterest"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faPinterest} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                            if(item.type === "faTiktok"){
                                                return(
                                                    <>
                                                        <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTiktok} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                                    </>
                                                )
                                            }
                                        }
                                    )
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Movil