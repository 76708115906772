import  React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Col, Row, Container, Card} from 'react-bootstrap'

import NavBusinessCard from "../components/BusinessCard/NavBusinessCard"
import FrmManBusinessCard from "../components/Forms/FrmManBusinessCard"
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faIdCardAlt } from '@fortawesome/free-solid-svg-icons'
import MyProvider from "../components/Application/provider"
import SocialMediaProvider from "../components/Application/providerSocialMedia"
import Movil from "../components/Movil"
import SocialMedia from "../components/SocialMedia"
import Access from "../components/Acess"
import { store } from "../components/Firebase/firebase-config"


const BusinessCard = () =>{


  return(
    <>
    <NavBusinessCard />
    <Seo title="Tarjeta de presentación" />
    <Access section="businessCard"/>

    <MyProvider>
      <Container fluid>
        <Row style={
          {
            "backgroundColor": "#6389FF", 
            "borderBottomLeftRadius": "30px", 
            "borderBottomRightRadius": "30px"
          }
          }>
          <Col md={3}>
            <Card className=" mt-3 mb-3 shadow" style={{"borderRadius": "15px"}}>
                <Card.Body>
                  <Card.Title style={{"color": "#5C5C5C"}}>
                    <FontAwesomeIcon icon={faIdCardAlt} style={
                      {
                        "fontSize": "30px"
                      }
                    } /> Tarjeta de presentación
                  </Card.Title>
                  
                </Card.Body>
              </Card>
          </Col>
        </Row>
      </Container>
      {/* PROVIDER SOCIAL MEDIA */}
      <SocialMediaProvider>
        <Container fluid>
          <Row>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <Card className="mt-5 shadow">
                    <Card.Body className="">
                      <Container className="mt-4">
                      <h3>Configuración de tarjeta de presentación</h3>
                      </Container>
                      <div className="mt-3">
                        <Container>
                        <FrmManBusinessCard />
                        </Container>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="mt-5 mb-5 shadow">
                    <Card.Body className="">
                      <Container className="mt-4">
                      <h3>Configuración de redes sociales</h3>
                      </Container>
                      <div className="mt-3">
                        <Container>
                          <SocialMedia></SocialMedia>
                        </Container>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Card className="mt-5 mb-5 shadow">
                <Card.Body className="mt-3">
                  <Movil />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </SocialMediaProvider>
    </MyProvider>
    
    {/* SECTION IMPORT COMPONENTS TYPE MODALS */}
  </>
  )
}

export default BusinessCard
