import {store} from '../../../Firebase/firebase-config';
import toastMsg from '../../../Toast';


const create = {};

create.add= async (e, json)=>{
    e.preventDefault();
    const businessCard =  store.collection('businessCard');
    const query =  businessCard.where("user", "==", `${localStorage.getItem("user")}`, true).get()
    .then(async (querySnapshot) => {
        if(querySnapshot.size ==0){
            await store.collection('businessCard').add({
                name: json.name,
                post:json.post,
                description: json.description,
                user: localStorage.getItem("user"),
                phone: json.phone,
                picture: json.picture,
                path: json.path,

            })
            .then(()=>{
                toastMsg.successToast("Perfil actualizado con exito");
            })
            .catch((err)=>{
                toastMsg.errorToast(err);
            })
        }else{
            console.log(json)
            querySnapshot.forEach(async(doc) => {
                // doc.data() is never undefined for query doc snapshots
                await store.collection('businessCard').doc(doc.id).set({
                    name: json.name,
                    post: json.post,
                    description: json.description,
                    user: localStorage.getItem("user"),
                    phone: json.phone,
                    picture: json.picture,
                    path: json.path,
                })
                .then(()=>{
                    toastMsg.successToast("Perfil actualizado con exito");
                })
                .catch((err)=>{
                    toastMsg.errorToast(err);
                })
    
            });
        }
    })
    .catch((err)=>{
        console.log(err)
    });
    

    
};

export default create;
