import React,{useContext, useState} from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import {SocialMediaContext} from '../../Application/providerSocialMedia';
import {store} from '../../Firebase/firebase-config'
import {toastMsg} from '../../Toast';
import Swal from "sweetalert2";


// sweet alert component



const FrmSocialMedia = () => {

    // const [social, setSocial] = useState('');
    // const [url, setUrl] = useState('');
    //USE CONTEXT
    const {setListExist, socialMediaList, setSocialMediaList, setShow, social, setSocial, url, setUrl, idSocial, path, setPath}= useContext(SocialMediaContext); 


    const readSocialMedia = async ()=>{
        const socialMedia =  store.collection('socialMedia');
        const query =  socialMedia.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async (querySnapshot) => {
            if(querySnapshot.size ==0){
                toastMsg.infoToast("Ingrese su primera red social");
                setListExist(false);
            }else{
                var array = [];
                querySnapshot.forEach(async(doc) => {
                    var json = {
                        type: doc.data().type,
                        url: doc.data().url,
                        id: doc.id,
                        user: doc.data().user
                    };
                    array.push(json); 
                });
                console.log(array)
                setSocialMediaList(array);
                setListExist(true);
            }
        })
        .catch((err)=>{
            console.log(err)
        });
    }



    const createSocialMedia = async()=>{
        if(idSocial != ""){
            try {
                if(!url.trim()){
                    Swal.fire({
                        icon: "error",
                        title: 'Error',
                        text: 'No puedes dejar la url vacia',
                    });
                    return;
                }
                const urlUpdate = {
                    path: path,
                    type: social,
                    url: url,
                    user: localStorage.getItem("user"),

                };
                await store.collection("socialMedia").doc(idSocial).set(urlUpdate)
                .then(()=>{
                    readSocialMedia();
                    Swal.fire({
                        icon: "success",
                        title: 'Exito',
                        text: 'Actualización de la red social realizada co exito.',
                    });
                    setShow(false);
                })
                
           } catch (error) {
               alert("error");
           }
        }else{
           await store.collection('socialMedia').add({
            path: path,
            type: social,
            url,
            user: localStorage.getItem("user"),
            })
            .then(()=>{
                setListExist(true);
                setSocialMediaList(socialMediaList);
                // toastMsg.successToast("Red social guardada con exito");
                readSocialMedia();
                Swal.fire({
                    icon: "success",
                    title: 'Exito',
                    text: 'Red Social Agregada con Exito',
                });
                setShow(false);
            })
            .catch((err)=>{
                console.log(err)
            }) 
        }
        
    };
    return (
        <>
            <ToastContainer />
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                    <div className="mb-3">
                        <label for="slC" className="form-label">Red Social</label>
                        <select 
                            className="form-select" aria-label="Default select example" 
                            id="slC"
                            onChange={(e)=>{
                                setSocial(e.target.value);
                            }}
                            value={social}
                        >
                            <option selected>Selecciona una red social</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Pinterest">Pinterest</option>
                            <option value="Tiktok">TikTok</option>
                            <option value="Telegram">Telegram</option>
                            <option value="Twitter">Twiter</option>
                            <option value="Whatsapp">WhatsApp</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputPassword1" className="form-label">URL</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="exampleInputPassword1"
                            placeholder="Ingresa la url de la red social."
                            onChange={(e)=>{
                                setUrl(e.target.value);
                            }}
                            value={url}
                        />
                    </div>
                    <button 
                        type="button"
                         className="btn btn-success btn-block"
                         onClick={()=>{
                            createSocialMedia();
                         }}
                    >Guardar</button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default FrmSocialMedia
