import React, {createContext, useState, useMemo} from 'react';


const MyProvider = (props) =>{
    const [name, setName] = useState('');
    const [post, setPost] = useState('');
    const [description, setDescription] = useState('');
    const [path, setPath] = useState('');
    const [picture, setPicture] = useState(null);

    return(
        <div>
            <AppContext.Provider value={
                [name, setName, post, setPost, 
                    description, setDescription, path, setPath, picture, setPicture]}>
                {props.children}
            </AppContext.Provider>
            
        </div>
    )
};


export default MyProvider;
export const AppContext = createContext();