// name: FrProduct
// description: Form product whit add and edit
// author: OsmaroBonilla
// date 11/10/2021
import { store, storage } from '../../Firebase/firebase-config';
import React,{useContext, useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';

import { AppContext } from "../../Application/provider";
import create from './actions/create';
import toastMsg from '../../Toast';



const FrmManBusinessCard = (props) => {

    const [
        name, 
        setName, 
        post, 
        setPost, 
        description, 
        setDescription, 
        path, 
        setPath, 
        picture, 
        setPicture
    ] = useContext(AppContext);
    const [phone, setPhone] = useState('');
    const [uploadValue, setUploadValue] = useState(0);
    const [userName, setUserName] = useState('');


    const readRole = ()=>{
    
        const roleUser = store.collection("roles")
        roleUser.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async(querySnapshot)=>{
          if(querySnapshot.size !=0){
            querySnapshot.forEach(async(doc) =>{
                setUserName(doc.data().username)
              })
          }else{
              console.log("Not found data roles")
          }
        })
    }


    const onUpload = (e)=>{
        const file = e.target.files[0];
        const storageRef = storage.ref(`/users/${userName}${new Date()}${file.name}`);
        const task = storageRef.put(file);
        task.on('state_changed', function(snapshot){
            let percentage = (snapshot.bytesTransferred/ snapshot.totalBytes) *100;
            setUploadValue(percentage);

            console.log(snapshot.state);
            }, function(error) {
            // Handle unsuccessful uploads
                console.log(error)
            }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                setPicture(downloadURL);
                alert("Archivo cargado");
            });
        });
    };
    async function read  (){
        
        const businessCard =  store.collection('businessCard');
        const query =  businessCard.where("user", "==", `${localStorage.getItem("user")}`, true).get()
        .then(async (querySnapshot) => {
            if(querySnapshot.size ==0){
                toastMsg.infoToast("Debe de ingresar su información");
            }else{
                querySnapshot.forEach(async(doc) => {
                    setName(doc.data().name);
                    setPost(doc.data().post);
                    setDescription(doc.data().description);
                    setPhone(doc.data().phone);
                    setPath(doc.data().path);
                    setPicture(doc.data().picture);
                });
            }
        })
        .catch((err)=>{
            console.log(err)
        });
    };

    useEffect(() => {
        read();
        readRole();
    }, [])

    return(
        <>
            <ToastContainer />
          <form>
            <div className="row m-2 form-group">
                <div className="col-12">
                    <div className="text-center">
                        <img src={picture} alt="img" style={{"borderRadius": "100px", "width": "200px", "height": "200px"}}/>
                    </div>
                </div>
                <div className="col-3"></div>
                <div className="col-6 mt-5 mb-5">
                    <div className="text-center">
                        <progress className="progress text-center" value={uploadValue} max="100" style={{"width": "100%"}}></progress>
                        <br />
                        <input 
                            type="file" 
                            class="form-control" 
                            id="validatedCustomFile" 
                            required 
                            onChange={(e)=>{
                                onUpload(e);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-2 form-group">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <label htmlFor="txtName" className="form-label">Nombre*</label>
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Ingresa nombre" 
                    id="txtName"
                    onChange={(e)=>{
                        setName(e.target.value);
                    }}
                    value={name}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <label htmlFor="txtPhone" className="form-label">Teléfono*</label>
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Ingresa nombre" 
                    id="txtName"
                    onChange={(e)=>{
                        setPhone(e.target.value);
                    }}
                    value={phone}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <label htmlFor="txtName" className="form-label">Puesto*</label>
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Ingresa nombre" 
                    id="txtName"
                    onChange={(e)=>{
                        setPost(e.target.value);
                    }}
                    value={post}
                />
              </div>
            </div>
            <div className="row m-2">
                <div className="col-12">
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Descripción</label>
                    <textarea 
                        class="form-control"
                        id="exampleFormControlTextarea1" 
                        rows="3"
                        onChange={(e)=>{
                            setDescription(e.target.value);
                        }}
                        value={description}
                    >

                    </textarea>
                </div>
                </div>
            </div>
            <div className="row m-2 ">
                <div className="col-12">
                    <button 
                        className="btn btn-success btn-block btn-sm float-end" 
                        onClick={(e)=>{
                            e.preventDefault();
                            const json = {
                                name: name,
                                post: post,
                                description: description,
                                phone: phone,
                                picture: picture,
                                path: "/"+userName
                            };
                            create.add(e,json);
                        }}
                    >Guardar</button>
                </div>
            </div>
          </form>
        </>
    )
}

export default FrmManBusinessCard